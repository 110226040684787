/* requires:
polyfill.js
*/

// display the current year in the footer
if (document.querySelector('.copyright-year')) {
  document.querySelector('.copyright-year').innerHTML = new Date().getFullYear();
}

(function () {
  var url = window.location.href;
  var en = document.querySelector('a.language-flag.en');
  var es = document.querySelector('a.language-flag.es');
  if (window.location.href.indexOf('spanish') != -1) {
    en.classList.remove('active');
    es.classList.add('active');
  } else {
    en.classList.add('active');
    es.classList.remove('active');
  }
})();

// this is a cross-domain cookie that triggers the use of the new cart v2
cart.setCookie('testVariant', 'useCartV2');
